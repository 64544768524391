import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {     
  obtenerCuentaContableSettings() {
    var url = ConfigAPI.baseURL + "accounting-settings" + Session.getToken();
    return instance.get(url);
  },
  editarCuentaContableSettings(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "accounting-settings/" + data.id + Session.getToken();
    return instance.post(url,params);
  },

  filtrarPlanCuenta(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "accounting-accounts/filter/query" + Session.getToken();
    return instance.post(url,params);
  },
  agregarPlanCuenta(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "accounting-accounts" + Session.getToken();
    return instance.post(url,params);
  },
  editarPlanCuenta(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "accounting-accounts/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },    
  eliminarPlanCuenta(id) {
    var url = ConfigAPI.baseURL + "accounting-accounts/"+ id + Session.getToken();
    return instance.delete(url);
  },     
  importarPlanCuenta(data, file) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }
  
    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="file") {
        formData.append(key, data[key]);
      }

      if(key=="file") {                
        file.forEach((value, key) => {          
          formData.append('file', value);
        })
      }      
    }

    var params = formData
    var url = ConfigAPI.baseURL + "accounting-accounts/import/CSV" + Session.getToken();
    
    return instance.post(url,params,{headers});
  },

  obtenerTiposDocumentos() {
    var url = ConfigAPI.baseURL + "sales-types-documents" + Session.getToken();
    return instance.get(url);
  },
  obtenerCondicionesFiscales() {
      var url = ConfigAPI.baseURL + "sales-fiscal-conditions" + Session.getToken();
      return instance.get(url);
  },     
  
  filtrarAsientos(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "accounting/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },
}

export default servicesAPI;